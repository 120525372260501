<template>
  <div class="product-filter box1">
    <div action="#" class="form mb-0">
      <h4>Filter By</h4>
      <div class="filter-options price-filter">
        <h6>Price</h6>
        <input type="range" class="form-control-range mb-3" v-model="price" :min="minPrice" :max="maxPrice"
          @change="priceChanged" data-toggle="tooltip" data-placement="top" :title="price" />
        <ul class="list">
          <li><input type="text" class="form-control" v-model="minPrice" readonly></li>
          <li><span>to</span></li>
          <li><input type="text" class="form-control" v-model="price" readonly></li>
        </ul>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#height-filter" role="button" aria-expanded="true"
          aria-controls="height-filter">
          Height of the product (mm)
        </h6>
        <div class="wrapper collapse show" id="height-filter">
          <VueMultiselect v-model="height" :options="heights" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select heights" :searchable="false" selectLabel="" deselectLabel=""
            @input="heightChanged" />
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#width-filter" role="button" aria-expanded="true"
          aria-controls="width-filter">
          Width of the Product (mm)
        </h6>
        <div class="wrapper collapse show" id="width-filter">
          <VueMultiselect v-model="width" :options="widths" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select widths" :searchable="false" selectLabel="" deselectLabel=""
            @input="widthChanged" />
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#power-filter" role="button" aria-expanded="true"
          aria-controls="power-filter">
          Maximum micro-wave power (W)
        </h6>
        <div class="wrapper collapse show" id="power-filter">
          <VueMultiselect v-model="maximumPower" :options="maximumPowers" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select maximum powers" :searchable="false" selectLabel=""
            deselectLabel="" @input="maximumPowerChanged" />
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#feature-filter" role="button" aria-expanded="true"
          aria-controls="feature-filter">
          Key Feature
        </h6>
        <div class="wrapper collapse show" id="feature-filter">
          <ul class="list">
            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="cookingGrill" id="feature-0" @change="cookingGrillChanged" />
                <label for="feature-0">Additional cooking method Grill</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="autoPilot" id="feature-1" @change="autoPilotChanged" />
                <label for="feature-1">Autopilot</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="turntable" id="feature-2" @change="turntableChanged" />
                <label for="feature-2">Turntable</label>
              </div>
            </li>

          </ul>
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#cavity-capacity-filter" role="button"
          aria-expanded="true" aria-controls="cavity-capacity-filter">
          Cavity capacity (l)
        </h6>
        <div class="wrapper collapse show" id="cavity-capacity-filter">
          <VueMultiselect v-model="cavityCapacity" :options="cavityCapacities" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select cavity capacities" :searchable="false" selectLabel=""
            deselectLabel="" @input="cavityCapacityChanged" />
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#colour-filter" role="button" aria-expanded="true"
          aria-controls="colour-filter">
          Main Colour of Product
        </h6>
        <div class="wrapper collapse show" id="colour-filter">
          <VueMultiselect v-model="color" :options="colors" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select colours" :searchable="false" selectLabel="" deselectLabel=""
            @input="colorChanged" />
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#type-filter" role="button" aria-expanded="true"
          aria-controls="type-filter">
          Type of micro-wave oven
        </h6>
        <div class="wrapper collapse show" id="type-filter">
          <VueMultiselect v-model="type" :options="types" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select types" :searchable="false" selectLabel="" deselectLabel=""
            @input="typeChanged" />
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#depth-filter" role="button" aria-expanded="true"
          aria-controls="depth-filter">
          Depth of the Product (mm)
        </h6>
        <div class="wrapper collapse show" id="depth-filter">
          <VueMultiselect v-model="depth" :options="depths" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select depths" :searchable="false" selectLabel="" deselectLabel=""
            @input="depthChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#installation-filter" role="button" aria-expanded="true"
          aria-controls="installation-filter">
          Installation Type
        </h6>
        <div class="wrapper collapse show" id="installation-filter">
          <VueMultiselect v-model="installation" :options="installations" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select installation types" :searchable="false" selectLabel=""
            deselectLabel="" @input="installationChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#control-filter" role="button" aria-expanded="true"
          aria-controls="type-filter">
          Type of control
        </h6>
        <div class="wrapper collapse show" id="control-filter">
          <VueMultiselect v-model="controlType" :options="controlTypes" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select control types" :searchable="false" selectLabel=""
            deselectLabel="" @input="controlTypeChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#cooking-mode-filter" role="button" aria-expanded="true"
          aria-controls="type-filter">
          Cooking Mode
        </h6>
        <div class="wrapper collapse show" id="cooking-mode-filter">
          <VueMultiselect v-model="cookingMode" :options="cookingModes" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select control types" :searchable="false" selectLabel=""
            deselectLabel="" @input="cookingModeChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#opening-filter" role="button" aria-expanded="true"
          aria-controls="type-filter">
          Door opening
        </h6>
        <div class="wrapper collapse show" id="opening-filter">
          <VueMultiselect v-model="doorOpening" :options="doorOpenings" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select control types" :searchable="false" selectLabel=""
            deselectLabel="" @input="doorOpeningChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#light-type-filter" role="button" aria-expanded="true"
          aria-controls="type-filter">
          Interior light
        </h6>
        <div class="wrapper collapse show" id="light-type-filter">
          <VueMultiselect v-model="lightType" :options="lightTypes" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select control types" :searchable="false" selectLabel=""
            deselectLabel="" @input="lightTypeChanged" />
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import _ from "lodash";
import ProductService from "@/services/product.service";
import VueMultiselect from "vue-multiselect";

export default {
  name: "FreestandingMicrowaveFilters",
  data: function () {
    return {
      heights: [],
      widths: [],
      maximumPowers: [],
      cavityCapacities: [],
      colors: [],
      types: [],
      depths: [],
      installations: [],
      controlTypes: [],
      cookingModes: [],
      lightTypes: [],
      doorOpenings: [],
      minPrice: 0,
      maxPrice: 0,

      price: 0,
      height: [],
      width: [],
      runtime: [],
      cavityCapacity: [],
      maximumPower: [],
      color: [],
      type: [],
      depth: [],
      installation: [],
      controlType: [],
      cookingMode: [],
      lightType: [],
      doorOpening: [],
      cookingGrill: false,
      autoPilot: false,
      turntable: false
    };
  },
  components: {
    VueMultiselect,
  },
  watch: {
    // call again the method if the route changes
    selectedBrands: function () {
      this.bindValues();
    },
  },
  mounted() {
    this.bindValues();
    if (this.selectedFilters.category == this.selectedSubCategory.id) {
      this.existingFilters();
    }
  },
  computed: {
    selectedSubCategory() {
      return this.$store.state.subCategory.selectedSubCategory;
    },
    selectedBrands() {
      return this.$store.state.brand.selectedBrands;
    },
    selectedFilters() {
      return this.$store.state.filters.selectedFilters;
    }
  },
  methods: {
    bindValues() {
      ProductService.minMaxPriceFor(
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.minPrice = response.price__min;
        this.maxPrice = response.price__max;
        this.price = response.price__max;
        if (this.selectedFilters.price__lte) {
          this.price = Number(this.selectedFilters.price__lte);
        }
      });
      ProductService.valuesFor(
        "attr_height",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.heights = response;
      });
      ProductService.valuesFor(
        "attr_width",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.widths = response;
      });
      ProductService.valuesFor(
        "attr_micro_wave_max_power",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.maximumPowers = response;
      });
      ProductService.valuesFor(
        "attr_cap_cavity",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.cavityCapacities = response;
      });
      ProductService.valuesFor(
        "attr_col_main",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.colors = response;
      });
      ProductService.valuesFor(
        "attr_mwo_type",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.types = response;
      });
      ProductService.valuesFor(
        "attr_depth",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.depths = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_inst_type",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.installations = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_control_setting",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.controlTypes = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_cooking_method",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.cookingModes = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_interior_light_type",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.lightTypes = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_door_opening",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.doorOpenings = response.filter(
          (item) => !["", null].includes(item)
        );
      });

    },


    priceChanged() {
      let filters = { price__lte: this.price };
      this.$emit("changeFilters", filters);
    },
    heightChanged() {
      let filters = {
        attr_height__in: _.map(this.height, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    widthChanged() {
      let filters = {
        attr_width__in: _.map(this.width, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    maximumPowerChanged() {
      let filters = {
        attr_micro_wave_max_power__in: _.map(this.maximumPower, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    cavityCapacityChanged() {
      let filters = {
        attr_cap_cavity__in: _.map(this.cavityCapacity, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    colorChanged() {
      let filters = {
        attr_col_main__in: _.map(this.color, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    typeChanged() {
      let filters = {
        attr_mwo_type__in: _.map(this.type, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    cookingGrillChanged() {
      let filters = {
        attr_grill_cooking: this.cookingGrill ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    autoPilotChanged() {
      let filters = {
        attr_autopilot: this.autoPilot ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    turntableChanged() {
      let filters = {
        attr_turntable: this.turntable ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },

    depthChanged() {
      let filters = {
        attr_depth__in: _.map(this.depth, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    installationChanged() {
      let filters = {
        attr_inst_type__in: _.map(this.installation, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    controlTypeChanged() {
      let filters = {
        attr_control_setting__in: _.map(this.controlType, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    cookingModeChanged() {
      let filters = {
        attr_cooking_method__in: _.map(this.cookingMode, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    lightTypeChanged() {
      let filters = {
        attr_interior_light_type__in: _.map(this.lightType, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    doorOpeningChanged() {
      let filters = {
        attr_door_opening__in: _.map(this.doorOpening, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    existingFilters() {
      this.cookingGrill = this.selectedFilters.attr_grill_cooking;
      this.autoPilot = this.selectedFilters.attr_autopilot;
      this.turntable = this.selectedFilters.attr_turntable;

      if (this.selectedFilters.attr_width__in) {
        let sortedWidths = [];
        let unsortedWidths = this.selectedFilters.attr_width__in.split('","');
        unsortedWidths.map((width) => {
          width = width.replaceAll('"', '')
          sortedWidths.push(width)
        });
        this.width = sortedWidths;
      }

      if (this.selectedFilters.attr_height__in) {
        let sortedHeights = [];
        let unsortedHeights = this.selectedFilters.attr_height__in.split('","');
        unsortedHeights.map((height) => {
          height = height.replaceAll('"', '')
          sortedHeights.push(height)
        });
        this.height = sortedHeights;
      }

      if (this.selectedFilters.attr_col_main__in) {
        let sortedColors = [];
        let unsortedColors = this.selectedFilters.attr_col_main__in.split('","');
        unsortedColors.map((color) => {
          color = color.replaceAll('"', '')
          sortedColors.push(color)
        });
        this.color = sortedColors;
      }

      if (this.selectedFilters.attr_micro_wave_max_power__in) {
        let sortedPowers = [];
        let unsortedPowers = this.selectedFilters.attr_micro_wave_max_power__in.split('","');
        unsortedPowers.map((power) => {
          power = power.replaceAll('"', '')
          sortedPowers.push(power)
        });
        this.maximumPower = sortedPowers;
      }

      if (this.selectedFilters.attr_cap_cavity__in) {
        let sortedCapacities = [];
        let unsortedCapacities = this.selectedFilters.attr_cap_cavity__in.split('","');
        unsortedCapacities.map((capacity) => {
          capacity = capacity.replaceAll('"', '')
          sortedCapacities.push(capacity)
        });
        this.cavityCapacity = sortedCapacities;
      }

      if (this.selectedFilters.attr_mwo_type__in) {
        let sortedTypes = [];
        let unsortedTypes = this.selectedFilters.attr_mwo_type__in.split('","');
        unsortedTypes.map((type) => {
          type = type.replaceAll('"', '')
          sortedTypes.push(type)
        });
        this.type = sortedTypes;
      }

      if (this.selectedFilters.attr_depth__in) {
        let sortedDepths = [];
        let unsortedDepths = this.selectedFilters.attr_depth__in.split('","');
        unsortedDepths.map((depth) => {
          depth = depth.replaceAll('"', "");
          sortedDepths.push(depth);
        });
        this.depth = sortedDepths;
      }

      if (this.selectedFilters.attr_inst_type__in) {
        let sortedInstallations = [];
        let unsortedInstallations = this.selectedFilters.attr_inst_type__in.split(
          '","'
        );
        unsortedInstallations.map((installation) => {
          installation = installation.replaceAll('"', "");
          sortedInstallations.push(installation);
        });
        this.installation = sortedInstallations;
      }

      if (this.selectedFilters.attr_control_setting__in) {
        let sortedControls = [];
        let unsortedControls = this.selectedFilters.attr_control_setting__in.split(
          '","'
        );
        unsortedControls.map((control) => {
          control = control.replaceAll('"', "");
          sortedControls.push(control);
        });
        this.controlType = sortedControls;
      }
      if (this.selectedFilters.attr_cooking_method__in) {
        let sortedModes = [];
        let unsortedModes = this.selectedFilters.attr_cooking_method__in.split(
          '","'
        );
        unsortedModes.map((mode) => {
          mode = mode.replaceAll('"', "");
          sortedModes.push(mode);
        });
        this.cookingMode = sortedModes;
      }
      if (this.selectedFilters.attr_interior_light_type__in) {
        let sortedLightTypes = [];
        let unsortedLightTypes = this.selectedFilters.attr_interior_light_type__in.split(
          '","'
        );
        unsortedLightTypes.map((light) => {
          light = light.replaceAll('"', "");
          sortedLightTypes.push(light);
        });
        this.lightType = sortedLightTypes;
      }
      if (this.selectedFilters.attr_door_opening__in) {
        let sortedOpenings = [];
        let unsortedOpenings = this.selectedFilters.attr_door_opening__in.split(
          '","'
        );
        unsortedOpenings.map((opening) => {
          opening = opening.replaceAll('"', "");
          sortedOpenings.push(opening);
        });
        this.doorOpening = sortedOpenings;
      }
    }
  },
};
</script>

<style scoped></style>